import Vue from "vue"
import VueMeta from "vue-meta"
import VueGtag from "vue-gtag"
import VuePortal from "@linusborg/vue-simple-portal"
import router from "@/router"
import store from "@/store"
import i18n, { setLocale } from "@/store/i18n"

import "@/plugins"

import "@/registerServiceWorker"
import "@/assets/styles/application.scss"

import pwaTheme from "@/lib/pwa-theme"
import { initEmbeddedGoogleFont } from "@/lib/fonts"
import { addGlobalCSS, addGlobalCode } from "@/lib/add-global-code"
import setupRewardful from "@/lib/rewardful"
import { VARIABLES } from "@/lib/globals"
import { setupStripe } from "@/lib/mixins/use-stripe"
import { globalState, refreshGlobalState } from "@/lib/global-state"

import App from "@/App.vue"
import { AppGlobalState } from "@shared/types"

Vue.config.productionTip = false

Vue.use(VueMeta, { keyName: "head" })

Vue.use(VuePortal)

if (["production", "staging"].includes(process.env.VUE_APP_ENV || "")) {
  Vue.use(VueGtag, { bootstrap: false }, router)
}

Vue.config.errorHandler = err => {
  if (process.env.NODE_ENV !== "production") {
    console.error(err)
  }
}

const initializeApp = (state: AppGlobalState | null) => {
  if (!state) return

  // Take care when changing this code as everything here must be idempotent.
  // This function gets called twice, once when loading from the local cache and once
  // after fetching from the backend.
  store.commit("setGlobalState", state)
  window[VARIABLES.GLOBAL_STATE] = state

  if (state.app.google_tag_manager_id) {
    const VueGtm = require("@gtm-support/vue2-gtm")

    try {
      Vue.use(VueGtm.default, {
        id: state.app.google_tag_manager_id,
        enabled: true,
        vueRouter: router
      })
    } catch (e) {
      console.error(e)
    }
  }

  if (state.theme && (state.theme.heading_font || state.theme.body_font)) {
    initEmbeddedGoogleFont()
  }

  pwaTheme(state.theme)

  if (state.theme.global_css) addGlobalCSS(state.theme.global_css)
  if (state.theme.global_head_code)
    addGlobalCode(state.theme.global_head_code, true)
  if (state.theme.global_body_code)
    addGlobalCode(state.theme.global_body_code, false)

  if (state.app.rewardful_id) {
    setupRewardful(state.app.rewardful_id)
  }

  // Should make available everywhere
  // https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
  if (state.payments.stripe_account_id) {
    setupStripe(state.payments.stripe_account_id)
  }

  setLocale(store.getters["localisation/currentLocale"])
}

// Set global state, first from local cache, then from the backend
initializeApp(globalState())
refreshGlobalState().then(initializeApp)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")
